import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import MediaQuery from 'react-responsive'
import moment from 'moment'

import SEO from '../components/seo'
import Layout from '../components/layout'
import styled from 'styled-components'
import submitBtn from '../assets/images/submit-button.svg'
import arrowUp from '../assets/images/arrow-up.svg'
import arrowDown from '../assets/images/arrow-down.svg'
import _ from 'lodash'

const BookingStyles = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: var(--white);
  }

  .with-bg {
    min-height: 66vh;

    @media screen and (min-width: 375px) {
      min-height: calc(100vh - 98px);
    }

    @media screen and (min-width: 768px) {
      min-height: 75vh;
      padding-bottom: 90px;
    }
  }

  @media screen and (min-width: 1024px) {
    .with-bg {
      min-height: auto;
    }
  }

  .booking-form {
    color: white;
    font-size: 27px;
  }

  @media (min-width: 768px) {
    .booking-form {
      font-size: 35px;
    }
  }

  @media screen and (min-width: 1024px) {
    .submit-button {
      height: 1.4em;
      vertical-align: top;
      width: 1.4em;
      display: block;
      margin: 0;
    }
  }

  @media screen and (max-width: 1023px) {
    .submit-button {
      height: 1.4em;
      vertical-align: top;
      display: block;
      margin: 0 auto;
      margin-top: 0.5em;
      display: flex;
      width: 100%;
      margin-top: 0.5em;
    }
    .length-of-days-wrapper {
      font-size: 20px;
      padding: 0px;
    }

    .check-in-input {
      font-size: 20px;
    }
  }

  .input-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0.3em;
    padding: 0px 20px;
  }

  .length-of-days-wrapper {
    border: 1px solid var(--white);
    display: flex;
    justify-content: center;
    // width: 2em;
    height: 2em;
    max-height: 45px;
  }

  .length-of-stay-input {
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 52px;
  }
  .length-of-stay-input::placeholder {
    color: inherit;
    opacity: 1;
  }

  .length-of-stay-input:focus,
  .length-of-stay-input:hover {
    outline: none;
    border-color: currentColor;
    border-style: solid;
  }
  .length-of-stay-input:focus {
    cursor: text;
  }

  .length-of-stay-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .check-in-input::placeholder {
    color: inherit;
    opacity: 1;
  }

  .length-of-days-wrapper {
    font-size: 26px;
    padding: 0px;
  }

  .check-in-input {
    font-size: 26px;
    text-transform: uppercase;
    border: 1px solid var(--white);
    background: transparent;
    cursor: pointer;
    display: inline-block;
    margin: 0 0.25em;
    text-align: center;
    width: 4em;
    height: 2em;
    max-height: 45px;
    border-radius: 0;
  }
  .check-in-input:focus,
  .check-in-input:hover {
    outline: none;
    border-color: currentColor;
    border-style: solid;
  }
  .check-in-input:focus {
    cursor: text;
  }

  .react-date-picker__calendar {
    color: black;
    font-size: 16px;
  }

  .react-date-picker__wrapper {
    border: none;
    color: #c6c6c6;
    font-size: inherit;
  }

  .react-date-picker__button svg {
    stroke: currentColor;
    width: auto;
    height: 1em;
  }

  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    color: white;
  }

  .react-date-picker__inputGroup__input::placeholder {
    opacity: 1;
    color: inherit;
  }

  .test,
  .end-date-input {
    background: transparent;
    color: inherit;
  }

  .submit-button-image {
    height: 100%;
    width: 100%;
  }
`

export const query = graphql`
  query BookingPageQuery {
    booking: sanityBooking {
      defaultStartDate
      text
      image {
        alt
        asset {
          _id
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      mobileImage {
        alt
        asset {
          _id
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      mobileImage2 {
        alt
        asset {
          _id
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      mobileImage3 {
        alt
        asset {
          _id
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

class BookingPage extends React.Component {
  constructor(props) {
    super(props)

    const defaultStart = props.data.booking.defaultStartDate || moment().format('YYYY-MM-DD')

    let defaultStartDateArray = defaultStart.split('-')

    let defaultStartDate = new Date(
      defaultStartDateArray[0],
      defaultStartDateArray[1] - 1,
      defaultStartDateArray[2]
    )
    let checkInDate = defaultStartDate

    let lengthOfStay = 1
    let currentImage = 'mobileImage'

    const copy = new Date(defaultStartDate)
    copy.setDate(parseInt(copy.getDate()) + parseInt(lengthOfStay))
    let checkOutDate = copy

    this.state = { defaultStartDate, checkInDate, lengthOfStay, checkOutDate, currentImage }
    this.state.mobileImageList = ['mobileImage', 'mobileImage2', 'mobileImage3']

    this.setCheckInDate = this.setCheckInDate.bind(this)
    this.handleLengthOfStayChange = this.handleLengthOfStayChange.bind(this)
    this.lengthOfStayInput = React.createRef()
  }

  componentDidMount() {
    setInterval(() => {
      this.changeMobileImage(this.state.mobileImageList)
    }, 5000)
  }

  changeMobileImage(mobileImageList) {
    let currentImage = this.state.currentImage
    let currentIndex = mobileImageList.indexOf(currentImage)
    let nextIndex = currentIndex + 1

    if (nextIndex >= mobileImageList.length) {
      nextIndex = 0
    }

    this.setState({ currentImage: mobileImageList[nextIndex] })
  }

  setCheckInDate(newValue) {
    let lengthOfStay = this.state.lengthOfStay !== '' ? this.state.lengthOfStay : 1
    let dateObject = {}

    if (newValue !== '') {
      dateObject = new Date(newValue)

      if (dateObject.getFullYear() === 2001 || dateObject.getFullYear() === 2000) {
        dateObject.setFullYear(new Date().getFullYear())
      }
    } else {
      dateObject = new Date(this.state.defaultStartDate)
    }

    const checkInDate = dateObject

    let copy = new Date(dateObject)
    copy.setDate(parseInt(dateObject.getDate()) + parseInt(lengthOfStay))
    const checkOutDate = copy

    this.setState({ checkInDateRaw: newValue, checkInDate, checkOutDate })
  }

  handleLengthOfStayChange(newValue) {
    if (newValue < 1) return
    
    let lengthOfStay = newValue !== '' ? newValue : 1
    const checkInDate = new Date(this.state.checkInDate)

    let copy = new Date(checkInDate)
    copy.setDate(parseInt(copy.getDate()) + parseInt(lengthOfStay))

    const checkOutDate = copy

    this.setState({ lengthOfStay: newValue, checkOutDate })
  }

  dateToYYYYMMDD(date) {
    let dateObj = Date.parse(date) ? new Date(date) : new Date()
    return dateObj.toISOString().slice(0, 10)
  }

  render() {
    const { image, mobileImage, text } = this.props.data.booking

    // image data
    const bookingImage = image
    const imageAsset = bookingImage.asset.fluid
    const imageAlt = image.alt

    const bookingText = text

    return (
      <Layout uri={this.props.uri}>
        <SEO />
        <BookingStyles>
          <MediaQuery maxWidth={767}>
            {matches =>
              matches ? (
                <>
                  {this.state.mobileImageList.map(key => {
                    const mobileBookingImage = this.props.data.booking[key]
                    const mobileImageAsset = mobileBookingImage.asset.fluid
                    const mobileImageAlt = mobileImage.alt
                    return (
                      <div
                        className={`absolute top-0 left-0 bottom-0 right-0 z-0 object-cover h-full w-full transition-all ${
                          this.state.currentImage === key ? 'opacity-100' : 'opacity-0'
                        }`}
                        style={{ filter: `brightness(0.6)` }}
                      >
                        <Img
                          className={`absolute h-full`}
                          fluid={mobileImageAsset}
                          alt={mobileImageAlt}
                        />
                      </div>
                    )
                  })}
                </>
              ) : (
                <div
                  className="absolute top-0 left-0 bottom-0 right-0 z-0 object-cover h-full w-full"
                  style={{ filter: `brightness(0.5)` }}
                >
                  <Img className="absolute h-full" fluid={imageAsset} alt={imageAlt} />
                </div>
              )
            }
          </MediaQuery>
          <div className="with-bg relative z-10 w-full h-full max-w-screen-lg flex justify-center items-center">
            <form
              className="booking-form sans-reg text-4xl px-1 md:text-5xl text-center flex-grow lg:flex "
              action="https://direct-book.com/piaule-catskill/properties/piaulecatskilldirect"
              target="_blank"
            >
              <div className="input-wrapper">
                {bookingText}
                <span className="length-of-days-wrapper">
                  <input
                    ref={this.lengthOfStayInput}
                    autoComplete="off"
                    className="length-of-stay-input text-center"
                    name="lengthOfStay"
                    onChange={event => {
                      this.handleLengthOfStayChange(event.target.value)
                    }}
                    type="number"
                    value={this.state.lengthOfStay}
                  />
                  <div style={{ height: '100%', width: 20 }}>
                    <div
                      style={{
                        borderLeft: 'solid 1px white',
                        height: '50%',
                        width: '100%',
                        borderBottom: '1px solid white',
                      }}
                      className="flex items-center justify-center cursor-pointer"
                      onClick={() => {
                        this.handleLengthOfStayChange(this.state.lengthOfStay + 1)
                      }}
                    >
                      <img className="" src={arrowUp} alt="Increase Days" />
                    </div>
                    <div
                      style={{
                        borderLeft: 'solid 1px white',
                        height: '50%',
                        width: '100%',
                      }}
                      className="flex items-center justify-center cursor-pointer"
                      onClick={() => {
                        this.handleLengthOfStayChange(this.state.lengthOfStay - 1)
                      }}
                    >
                      <img className="" src={arrowDown} alt="Decrease Days" />
                    </div>
                  </div>
                </span>
                <span>days starting on</span>
                <input
                  className="check-in-input"
                  onChange={event => {
                    this.setCheckInDate(event.target.value)
                  }}
                  placeholder={this.state.defaultStartDate.toLocaleString('default', {
                    month: 'short',
                    day: 'numeric',
                  })}
                  type="text"
                  value={this.state.checkInDateRaw}
                />
              </div>
              <button type="submit" className="submit-button">
                <img className="submit-button-image" src={submitBtn} alt="Submit Button" />
              </button>
              <input
                className="test"
                name="checkInDate"
                type="hidden"
                readOnly
                value={this.dateToYYYYMMDD(this.state.checkInDate)}
              />
              <input
                name="checkOutDate"
                type="hidden"
                readOnly
                className="end-date-input"
                value={this.dateToYYYYMMDD(this.state.checkOutDate)}
              />
            </form>
          </div>
        </BookingStyles>
      </Layout>
    )
  }
}

export default BookingPage
